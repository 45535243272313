var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-modal", {
        attrs: {
          id: "modal-component-form",
          size: "lg",
          "no-close-on-backdrop": "",
          "header-class": "d-block",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t("modals.createFormItem.choose_form_template"))
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "border-right-light-grey",
                        attrs: { cols: "12", sm: "3" }
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "form_templates pl-2 mt-5 pb-5" },
                          [
                            _c("li", { staticClass: "nav-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link",
                                  class: {
                                    active: _vm.selectedSharedItem === null
                                  },
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      return _vm.loadEmptyContent()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "modals.createFormItem.form.emptyForm"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    ),
                    _c("b-col", { attrs: { cols: "12", sm: "9" } }, [
                      _vm.selectedSharedItem === null
                        ? _c(
                            "div",
                            { staticClass: "m-5 pb-5" },
                            [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "modals.createItem.project.emptyProject"
                                    )
                                  )
                                )
                              ]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "modals.createItem.project.emptyProjectDesc"
                                    )
                                  )
                                )
                              ]),
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-0 ml-0 green-button",
                                  on: { click: _vm.showFormNameModal }
                                },
                                [
                                  _c("b-icon", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      icon: "plus-circle-fill",
                                      "aria-hidden": "true",
                                      scale: "1"
                                    }
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("webmag.select")) + " "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function({ close }) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return close()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                )
              ]
            }
          }
        ])
      }),
      _c("b-modal", {
        ref: "modal-add-new-form-item",
        attrs: {
          id: "modal-add-new-form-item",
          "footer-class": "flex-nowrap",
          "no-close-on-backdrop": "",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("modals.createFormItem.title")))
                ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("modal-choose-team-name-and-description", {
                  attrs: {
                    teamName: _vm.formItemName,
                    teamDescription: _vm.formItemDescription
                  },
                  on: {
                    "update-fields": _vm.updateFields,
                    "enter-pressed": _vm.createFormItem
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function({ close }) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return close()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: {
                      disabled: !_vm.isFormItemNameSet,
                      variant: "primary",
                      block: ""
                    },
                    on: { click: _vm.createFormItem }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("modals.createFormItem.validate")) +
                        " "
                    )
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }